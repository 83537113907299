import React from "react";
import SEO from "../../components/SEO";
import Layout from "../../components/Layout";
import ContactForm from "../../components/ContactForm";
import useSiteMetadata from "../../components/SiteMetadata";

function ContactPage() {
  const { companyname } = useSiteMetadata();

    const {
        services,
    } = useSiteMetadata();

  return (
    <Layout className="bg-gray-50">
      <SEO
        title={`Contact Us | ${companyname}`}
        description={`Contact ${companyname} today to learn more about our HERS Rater Testing, Duct Testing, QII and Title 24 in the Sacramento Valley!`}
      />

      <div className="container mx-auto">
        <div className="max-w-2xl container p-8 mx-auto">
          <div className="mb-6">
            <h1 className="font-extrabold text-2xl md:text-3xl">
              Talk to an Expert
            </h1>
            <p className="text-base md:text-lg text-gray-600">
                Contact us today to learn more about {services} services in Sacramento valley and surrounding areas. Experience what its like to work with capable and professional experts in the energy industry.



            </p>
          </div>

          <ContactForm fontsize={"text-lg"} />
        </div>
      </div>
    </Layout>
  );
}

export default ContactPage;
